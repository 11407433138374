.testimonials-container {
  background-color: var(--sectionBg);
  padding: 40px 0px;
}
.testimonials-image-main {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.testimonials-image {
  height: 400px;
}
.testimonials-heading {
  color: var(--secondary);
  font-size: 15px;
  font-weight: 700;
}
.testimonials-heading-2 {
  color: var(--primary);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
}
.testimonials-heading-2 > span {
  color: var(--secondary);
}
.testimonials-slider-container {
  background-color: var(--white);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
  cursor: grab;
  height: 360px;
}
.testimonials-quotation {
  width: 50px;
  height: 50px;
}
.testimonials-profile-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
}
.testimonials-profile {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}
.testimonials-description {
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  color: var(--secondary);
  text-align: justify;
}
.testimonials-user-name {
  font-size: 18px;
  font-weight: 700;
  color: var(--secondary);
}
.testimonials-postition {
  font-size: 11px;
  color: var(--secondary);
  margin: 5px 0px;
}
.testimonials-quotation-main {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: -40px;
}
.testimonials-data-main {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  flex: 1;
  justify-content: space-between;
  height: 290px;
}
@media only screen and (max-width: 1650px) {
  .testimonials-slider-container {
    height: 380px;
  }
  .testimonials-description {
    font-size: 16px;
    line-height: 28px;
  }
  .testimonials-quotation {
    width: 45px;
    height: 45px;
  }
  .testimonials-profile {
    width: 45px;
    height: 45px;
  }
  .testimonials-data-main {
    height: 320px;
  }
}
@media only screen and (max-width: 1400px) {
  .testimonials-description {
    font-size: 15px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 1020px) {
  .testimonials-quotation {
    width: 35px;
    height: 35px;
  }
  .testimonials-profile {
    width: 45px;
    height: 45px;
  }
  .testimonials-slider-container {
    padding: 20px;
  }
}
@media only screen and (max-width: 899px) {
  .testimonials-image-main {
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .testimonials-quotation {
    width: 50px;
    height: 50px;
  }
  .testimonials-profile {
    width: 50px;
    height: 50px;
  }
  .testimonials-description {
    font-size: 17px;
    line-height: 30px;
  }
  .testimonials-data-main {
    height: 290px;
  }
}
@media only screen and (max-width: 700px) {
  .testimonials-description {
    font-size: 16px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 599px) {
  .testimonials-container {
    padding: 40px 20px;
  }
}
@media only screen and (max-width: 500px) {
  .testimonials-quotation {
    width: 40px;
    height: 40px;
  }
  .testimonials-profile {
    width: 40px;
    height: 40px;
  }
  .testimonials-description {
    font-size: 14px;
    line-height: 24px;
  }
  .testimonials-slider-container {
    padding: 20px;
    height: 350px;
  }
}
@media only screen and (max-width: 400px) {
  .testimonials-quotation {
    width: 30px;
    height: 30px;
  }
  .testimonials-description {
    font-size: 12px;
    line-height: 22px;
  }
  .testimonials-user-name {
    font-size: 14px;
  }
  .testimonials-slider-container {
    padding: 20px;
    height: 310px;
  }
  .testimonials-data-main {
    height: 260px;
  }
  .testimonials-image {
    height: 300px;
  }
  .testimonials-heading-2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 360px) {
  .testimonials-heading-2 {
    font-size: 15px;
  }
  .testimonials-heading {
    font-size: 12px;
  }
  .testimonials-container {
    padding: 20px 20px;
  }
  .testimonials-quotation {
    width: 20px;
    height: 20px;
  }
  .testimonials-profile {
    width: 30px;
    height: 30px;
  }
  .testimonials-profile-main {
    margin-top: -20px;
  }
  .testimonials-description {
    font-size: 10px;
    line-height: 18px;
  }
  .testimonials-user-name {
    font-size: 14px;
  }
  .testimonials-postition {
    font-size: 9px;
  }
  .testimonials-image {
    height: 200px;
  }
}
