.breadcrumbs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
  position: relative;
}
.breadcrumbs-image{
  width: 100%;
}
.breadcrumbs-data-main{
  position: absolute;
  background-color: #212529a6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.breadcrumbs-heading {
  color: var(--white);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: capitalize;
}
.breadcrumbs-link-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.breadcrumbs-home-button {
  background-color: transparent !important;
  color: var(--white) !important ;
  text-transform: capitalize !important;
  font-size: 14px !important;
  font-style: normal !important;
  padding: 0px !important;
  font-weight: 600 !important;
  width: auto !important;
  min-width: auto !important;
}
.breadcrumbs-page {
  color: var(--white);
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.breadcrumbs-right-icon {
  color: var(--white);
  width: 14px;
  height: 14px;
  margin: 0px 2px;
}
@media only screen and (max-width: 400px) {
  .breadcrumbs-heading {
    font-size: 16px;
  }
  .breadcrumbs-home-button {
    font-size: 10px !important;
  }
  .breadcrumbs-page {
    font-size: 10px;
  }
  .breadcrumbs-right-icon {
    width: 10px;
  }
}
