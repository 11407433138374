.web-development-section {
  background-color: var(--sectionBg);
  margin-top: 40px;
  padding: 30px 0px;
}
.web-development-section-heading {
  color: var(--primary);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.web-development-section-description {
  color: var(--secondary);
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  margin-top: 20px;
}
@media only screen and (max-width: 599px) {
  .web-development-section {
    padding: 20px 20px;
  }
  .web-development-section-heading {
    text-align: left;
    font-size: 20px;
  }
  .web-development-section-description {
    text-align: left;
    font-size: 15px;
    line-height: 30px;
  }
}
