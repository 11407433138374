.footer-container {
  background-image: url("./../assets/images/footer-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3% 5%;
}
.footer-logo-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-logo-button {
  padding: 0px !important;
}
.footer-logo {
  height: 116px;
}
.footer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
}
.footer-country-flag {
  height: 40px;
}
.footer-country-name {
  font-size: 17px;
  font-weight: 700;
  color: var(--primary);
  margin-left: 10px;
  text-transform: uppercase;
}
.footer-button {
  padding: 0px !important;
  width: 100%;
  justify-content: flex-start !important;
  text-transform: lowercase !important;
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: transparent !important;
  margin-top: 5px !important;
}
.footer-button-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: var(--primary);
}
.footer-button-2 {
  padding: 0px !important;
  width: 100%;
  justify-content: flex-start !important;
  text-transform: auto !important;
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  background-color: transparent !important;
  margin-top: 5px !important;
  text-align: left !important;
  align-items: flex-start !important;
}
.footer-section {
  border-left: 2px solid var(--primary);
  padding-left: 20px;
}
.footer-web-link-button {
  padding: 0px !important;
  width: 100%;
  justify-content: flex-start !important;
  text-transform: auto !important;
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  margin-top: 5px !important;
}
@media only screen and (max-width: 899px) {
  .footer-logo {
    height: 80px;
  }
}
@media only screen and (max-width: 599px) {
  .footer-section {
    border-left: 0px solid var(--primary);
    padding-left: 0px;
  }
  .footer-header {
    margin-top: 30px;
  }
  .footer-container {
    padding: 5% 5%;
  }
}
@media only screen and (max-width: 450px) {
  .footer-logo {
    height: 50px;
  }
}
