.artificial-intelligence-list {
  font-size: 17px;
  font-weight: 400;
  color: var(--secondary);
  list-style-position: inside;
  margin-top: 30px;
}
.artificial-intelligence-list > li {
  margin-top: 7px;
}
@media only screen and (max-width: 1550px) {
  .artificial-intelligence-list {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1300px) {
  .artificial-intelligence-list {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .artificial-intelligence-list {
    font-size: 13px;
  }
}
@media only screen and (max-width: 799px) {
  .artificial-intelligence-list {
    font-size: 11px;
  }
}
