.sub-header-container {
  background-color: var(--primary);
  padding: 7px 0px;
}
.sub-header-call-button {
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-right: 1px solid var(--white) !important;
  border-radius: 0px !important;
  padding: 0px !important;
  padding-right: 20px !important;
}
.sub-header-call-button-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.sub-header-email-button {
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-radius: 0px !important;
  padding: 0px !important;
  padding-left: 20px !important;
  text-transform: lowercase !important;
}
.sub-header-email-button-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.sub-header-2nd-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.sub-header-social-button {
  padding: 0px !important;
  width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  border-radius: 100px !important;
  border: 2px solid var(--white) !important;
  color: var(--white) !important;
  margin-left: 6px !important;
  font-size: 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  align-self: center !important;
}
@media only screen and (max-width: 899px) {
  .sub-header-1st-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }
  .sub-header-2nd-section {
    justify-content: center;
    padding: 0px 20px;
  }
  .sub-header-container {
    padding: 0px 0px;
  }
}
@media only screen and (max-width: 400px) {
  .sub-header-call-button {
    font-size: 12px !important;
  }
  .sub-header-call-button-icon {
    width: 15px;
    height: 15px;
  }
  .sub-header-email-button {
    font-size: 12px !important;
  }
  .sub-header-email-button-icon {
    width: 15px;
    height: 15px;
  }
  .sub-header-social-button {
    width: 25px !important;
    min-width: 25px !important;
    height: 25px !important;
    min-height: 25px !important;
    font-size: 12px !important;
  }
}
@media only screen and (max-width: 360px) {
  .sub-header-call-button {
    font-size: 10px !important;
    padding-right: 5px !important;
  }
  .sub-header-call-button-icon {
    width: 10px;
    height: 10px;
  }
  .sub-header-email-button {
    font-size: 10px !important;
    padding-left: 5px !important;
  }
  .sub-header-email-button-icon {
    width: 10px;
    height: 10px;
  }
  .sub-header-social-button {
    width: 20px !important;
    min-width: 20px !important;
    height: 20px !important;
    min-height: 20px !important;
    font-size: 10px !important;
    border-width: 1px !important;
  }
}
