.seo-section{
    background-color: var(--sectionBg);
    margin: 30px 0px;
    padding: 30px 0px;
}
.seo-section-heading{
    color: var(--primary);
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
}
.seo-section-list{
    font-size: 15px;
    color: var(--secondary);
    line-height: 22px;
    list-style-position: inside;
}
.seo-section-list>li{
    margin-top: 10px;
}
@media only screen and (max-width: 599px) {
    .seo-section{
        margin: 30px 0px;
        padding: 20px 20px;
    }  
    .seo-section-heading{
        font-size: 20px;
        margin-bottom: 10px;
    }
    .seo-section-list>li{
        margin-top: 5px;
    }
}