.navbar-container {
  background-color: var(--white);
  padding: 7px 0px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.navbar-inner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.navbar-logo-button {
  padding: 0px !important;
}
.navbar-logo {
  height: 60px;
}
.navbar-2nd-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar-dropdown {
  position: relative;
  display: inline-block;
  margin-left: 20px;
}

.navbar-dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  padding-top: 23px;
  min-width: 270px;
  background-color: var(--white);
}
.navbar-dropdown:hover .navbar-dropdown-content {
  display: block;
}
.navbar-item {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--secondary) !important;
  text-transform: capitalize !important;
  margin-left: 20px !important;
  padding: 0px !important;
  background-color: transparent !important;
}
.navbar-item:hover {
  color: var(--primary) !important;
}
.navbar-active-item {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--primary) !important;
  text-transform: capitalize !important;
  margin-left: 20px !important;
  padding: 0px !important;
  background-color: transparent !important;
}
.navbar-serviecs-item {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--secondary) !important;
  text-transform: capitalize !important;
  padding: 0px !important;
  background-color: transparent !important;
}
.navbar-serviecs-item:hover {
  color: var(--primary) !important;
}
.navbar-dropdown:hover .navbar-serviecs-item {
  color: var(--primary) !important;
}
.navbar-services-down-icon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
.navbar-dropdown-content-items-main {
  background-color: var(--white);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px 0px;
}
.navbar-dropdown-item {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: var(--secondary) !important;
  text-transform: capitalize !important;
  padding: 10px 10px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  opacity: 1px !important;
  background-color: transparent !important;
}
.navbar-dropdown-item:hover {
  color: var(--primary) !important;
}
.navbar-dropdown-active-item {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: var(--primary) !important;
  text-transform: capitalize !important;
  padding: 10px 10px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  opacity: 1px !important;
  background-color: transparent !important;
}
.navbar-dropdown-icon {
  width: 24px;
  height: 24px;
  padding: 1px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.navbar-mobile-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}
.navbar-drawer-button {
  padding: 0px !important;
  width: 35px !important;
  min-width: 35px !important;
  height: 35px !important;
  min-height: 35px !important;
  border-radius: 100px !important;
  color: var(--white) !important;
  font-size: 30px !important;
  color: var(--primary) !important;
}
.navbar-mobile-view-logo-button {
  padding: 0px !important;
}
.navbar-mobile-view-logo {
  height: 30px;
}
.navbar-get-quote-button {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--white) !important;
  text-transform: capitalize !important;
  padding: 0px !important;
  background-color: var(--primary) !important;
  border-radius: 0px !important;
  height: 40px !important;
  width: 150px !important;
}
