.testimonials-video-container {
  margin: 30px 0px;
}
.testimonials-video-heading {
  color: var(--secondary);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.testimonials-video-heading > span {
  color: var(--primary);
}
.testimonials-video {
  height: 600px;
  width: 100%;
}
@media only screen and (max-width: 1550px) {
  .testimonials-video {
    height: 550px;
  }
}
@media only screen and (max-width: 1300px) {
  .testimonials-video {
    height: 500px;
  }
}
@media only screen and (max-width: 899px) {
  .testimonials-video {
    height: 400px;
  }
}
@media only screen and (max-width: 700px) {
  .testimonials-video {
    height: 350px;
  }
}
@media only screen and (max-width: 650px) {
  .testimonials-video {
    height: 300px;
  }
}
@media only screen and (max-width: 599px) {
  .testimonials-video-container {
    margin: 30px 20px;
  }
}
@media only screen and (max-width: 500px) {
  .testimonials-video {
    height: 250px;
  }
}
@media only screen and (max-width: 400px) {
  .testimonials-video {
    height: 220px;
  }
  .testimonials-video-heading {
    font-size: 20px;
  }
}
