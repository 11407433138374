.step-card-container {
  background-color: var(--sectionBg);
  padding: 100px 0px;
  background-image: url("./../assets/images/step-card-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 50px 0px;
}

.step-card-heading {
  color: var(--white);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.step-card-sub-heading {
  color: var(--white);
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 50px;
}
.step-card-sub-heading > span {
  color: var(--primary);
}
.step-card-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  height: 420px;
  background-color: rgba(0, 0, 0, 0.52);
  border: 3px solid var(--primary);
  border-radius: 10px;
}
.step-card-1-icon-main {
  background-color: var(--primary);
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-card-1-icon {
  width: 40px;
  height: 40px;
}
.step-card-1-title {
  color: var(--white);
  font-size: 22px;
  font-weight: 700px;
  text-align: center;
  margin-top: 20px;
}
.step-card-1-description {
  color: var(--white);
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  margin-top: 20px;
}
.step-card-description {
  color: var(--white);
  text-align: center;
  font-size: 17px;
  margin-top: 5px;
  margin: 10px 20px;
}
.step-card-header {
  margin-bottom: 50px;
}
@media only screen and (max-width: 899px) {
  .step-card-1 {
    padding: 50px 15px;
    height: auto;
  }
}
@media only screen and (max-width: 670px) {
  .step-card-heading {
    font-size: 22px;
  }
  .step-card-sub-heading {
    font-size: 22px;
  }
}
@media only screen and (max-width: 599px) {
  .step-card-container {
    padding: 50px 20px;
    margin: 30px 0px;
  }

  .step-card-description {
    font-size: 15px;
  }
}
@media only screen and (max-width: 500px) {
  .step-card-heading {
    font-size: 16px;
  }
  .step-card-sub-heading {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .step-card-heading {
    font-size: 14px;
  }
  .step-card-sub-heading {
    font-size: 11px;
    margin-bottom: 20px;
  }
}
