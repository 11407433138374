* {
  padding: 0px;
  margin: 0px;
}
* {
  font-family: "Poppins", sans-serif;
}
html {
  overflow: visible;
}
:root {
  --primary: #bc2026;
  --primaryLight: #bc2025d7;
  --secondary: #4d4d4d;
  --white: #fff;
  --black: #000;
  --whatsApp: #4ac758;
  --linkedin: #0a63bc;
  --youtube: #f60002;
  --twitter: #1c96e8;
  --facebook: #3358a2;
  --instagram: #8e4bc2;
  --lightGray: rgba(128, 128, 128, 0.152);
  --sectionBg: #f6f9fd;
}
::-moz-selection {
  color: var(--white);
  background: var(--primary);
}
::selection {
  color: var(--white);
  background: var(--primary);
}
