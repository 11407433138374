.portfolio-container {
  padding: 50px 0px;
  background-image: url('./../assets/images/portfolio-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.portfolio-heading {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: var(--black);
  text-transform: uppercase;
}
.portfolio-sub-heading {
  font-size: 25px;
  font-weight: 700;
  color: var(--primary);
  text-align: center;
  margin-bottom: 20px;
}
.portfolio-sub-heading > span {
  color: var(--white);
}
.portfolio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.portfolio-card-image{
  width:100%;
  height: 100%;
}
.portfolio-card-title {
  color: var(--white);
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.portfolio-card-description {
  color: var(--white);
  font-size: 15px;
  margin-top: 20px;
  text-align: center;
}
.portfolio-card-view-button {
  color: var(--white) !important;
  border-radius: 0px !important;
  padding: 0px 30px !important;
  margin-top: 15px !important;
  height: 54px !important;
  text-transform: capitalize !important;
  font-size: 15px !important;
  background-color:var(--primary) !important;
  min-width: 200px !important;
}
@media only screen and (max-width: 500px) {
  .portfolio-heading {
    font-size: 20px;
    margin: 0px 20px;
  }
  .portfolio-sub-heading {
    font-size: 20px;
    margin: 0px 20px;
    margin-bottom: 20px;
  }
}
