@media only screen and (max-width: 1550px) {
  .pages-section-description {
    color: var(--secondary);
    font-size: 16px;
    line-height: 33px;
  }
}
@media only screen and (max-width: 1300px) {
  .pages-section-description {
    color: var(--secondary);
    font-size: 15px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 1000px) {
  .pages-section-heading {
    font-size: 20px;
  }
  .pages-section-description {
    color: var(--secondary);
    font-size: 13px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 799px) {
  .pages-section-heading {
    font-size: 16px;
  }
  .pages-section-description {
    color: var(--secondary);
    font-size: 11px;
    line-height: 20px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 599px) {
  .pages-section {
    margin: 30px 20px;
  }
}
