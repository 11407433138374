.sub-footer-container {
  background-color: var(--primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0px 20px;
}
.sub-footer-copyright {
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
}
.sub-footer-button {
  background-color: transparent !important;
  padding: 0px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: var(--white) !important;
  text-transform: capitalize !important;
  margin: 0px 5px !important;
}
