.layout-container{
  position: relative;
}
.share-container {
  position: fixed;
  height: 100vh;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.share-button {
  padding: 0px !important;
  width: 45px !important;
  min-width: 45px !important;
  height: 45px !important;
  min-height: 45px !important;
  border-radius: 0px !important;
  margin: 3px 0px !important;
  color: var(--white) !important;
  font-size: 25px !important;
}
.share-whatsapp-button {
  background-color: var(--whatsApp) !important;
  margin-top: -150px !important;
}
.share-linkedin-button {
  background-color: var(--linkedin) !important;
}
.share-youtube-button {
  background-color: var(--youtube) !important;
}
.share-twitter-button {
  background-color: var(--twitter) !important;
}
.share-facebook-button {
  background-color: var(--facebook) !important;
}
.share-instagram-button {
  background-color: var(--instagram) !important;
}
.layout-header {
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 1000;
}
.layout-navbar {
  margin-top: 0px;
}
.layout-page-data-container {
  margin-top: 120px;
}
@media only screen and (max-width: 899px) {
  .layout-page-data-container {
    margin-top: 125px;
  }
}
@media only screen and (max-width: 400px) {
  .layout-page-data-container {
    margin-top: 121px;
  }
}
