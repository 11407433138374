.about-us-section-1-image-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.about-us-section-1-image{
  max-height: 100%;
  max-width: 100%;
}
@media only screen and (max-width: 599px) {
  .about-us-section-1-image-main {
    margin-top: 20px;
  }
}
