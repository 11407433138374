.get-in-touch-container {
  background-image: url("./../assets/images/get-in-touch.png");
  height: 381px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin: 40px 0px;
}
.get-in-touch-inner-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.get-in-touch-heading {
  color: var(--white);
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
}
.get-in-touch-description {
  color: var(--white);
  text-align: center;
  font-size: 17px;
  line-height: 30px;
  margin-top: 10px;
}
.get-in-touch-button-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.get-in-touch-button {
  color: var(--white) !important;
  border-radius: 0px !important;
  padding: 0px 30px !important;
  background-color: var(--primary) !important;
  height: 45px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
}
@media only screen and (max-width: 899px) {
  .get-in-touch-heading {
    font-size: 25px;
  }
  .get-in-touch-description {
    font-size: 16px;
    line-height: 28px;
  }
  .get-in-touch-container {
    height: 350px;
  }
}
@media only screen and (max-width: 599px) {
  .get-in-touch-container {
    height: 300px;
  }
  .get-in-touch-heading {
    font-size: 25px;
    margin: 0px 20px;
  }
  .get-in-touch-description {
    margin: 10px 20px 0px 20px;
  }
  .get-in-touch-heading {
    font-size: 20px;
  }
  .get-in-touch-description {
    font-size: 14px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 400px) {
  .get-in-touch-heading {
    font-size: 16px;
  }
  .get-in-touch-description {
    font-size: 12px;
    line-height: 22px;
  }
}