.pages-section {
  margin: 50px 0px;
}
.pages-section-data-main{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pages-section-heading {
  color: var(--primary);
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
}
.pages-section-description {
  color: var(--secondary);
  font-size: 17px;
  font-weight: 500;
  text-align: justify;
  line-height: 35px;
  margin-top: 20px;
}
.pages-section-image-main{
  height: 100%;
  display: flex;
  justify-content: center;
}
.pages-section-image {
max-width: 100%;
max-height: 100%;
}
