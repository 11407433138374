.tabs-container {
  background-color: var(--white);
}
.tabs-heading {
  color: var(--secondary);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
}
.tabs-heading > span {
  color: var(--primary);
}
.tabs-header-title {
  font-size: 17px;
  text-align: center;
  color: var(--secondary);
  margin-top: 10px;
}
.tabs-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 30px;
  margin-top: 30px;
}
.tabs-header-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.tabs-header-button {
  border: 1px solid var(--lightGray) !important;
  flex: 1 !important;
  border-radius: 0px !important;
  background-color: var(--white) !important;
  flex-direction: column !important;
  padding: 20px !important;
  min-width: 250px !important;
}
.tabs-header-active-button {
  flex: 1 !important;
  flex-direction: column !important;
  border-radius: 0px !important;
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  padding: 20px !important;
  min-width: 250px !important;
}
.tabs-down-icon {
  position: absolute;
  bottom: -22px;
  width: 30px;
  height: 30px;
  color: var(--lightGray);
}
.tabs-down-active-icon {
  position: absolute;
  bottom: -22px;
  width: 30px;
  height: 30px;
  color: var(--primary);
}
.tabs-active-title {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.tabs-title {
  color: var(--secondary);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.tabs-heading-2 {
  color: var(--primary);
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.tabs-description {
  font-size: 17px;
  font-weight: 500;
  line-height: 35px;
  color: var(--secondary);
  text-align: justify;
  margin-top: 15px;
}
.tabs-image-main {
  height: 410px;
  width: 100%;
  max-height: auto;
  display: flex;
  justify-content: center;
}
.tabs-image {
  max-height: 100%;
  max-width: 100%;
}
.tabs-details-show{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media only screen and (max-width: 1650px) {
  .tabs-heading-2 {
    font-size: 20px;
  }
  .tabs-description {
    font-size: 15px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 1550px) {
  .tabs-heading-2 {
    font-size: 16px;
  }
  .tabs-description {
    font-size: 12px;
    line-height: 28px;
  }
  .tabs-active-title {
    font-size: 13px;
  }
  .tabs-title {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1199px) {
  .tabs-header-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
  }
  .tabs-header-button {
    padding: 15px 20px !important;
  }
  .tabs-header-active-button {
    padding: 15px 20px !important;
  }
}
@media only screen and (max-width: 899px) {
  .tabs-header-icon {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
  .tabs-header-button {
    padding: 10px 20px !important;
  }
  .tabs-header-active-button {
    padding: 10px 20px !important;
  }
}
@media only screen and (max-width: 599px) {
  .tabs-container {
    margin: 0px 20px;
  }
  .tabs-header-title {
    font-size: 15px;
  }
  .tabs-image-main {
    justify-content: center;
  }
}
@media only screen and (max-width: 360px) {
}
