.clients-container {
  /* background-color: var(--primary); */
  padding: 30px 0px;
}
.clients-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  cursor: grab;
  height: 110px;
  width: 96%;
  background-color: var(--white);
}
.clients-slider-logo {
max-height: 100%;
max-width: 100%;
}
@media only screen and (max-width: 599px) {
  .clients-container {
    padding: 20px 20px;
  }
}