.contact-us-section-container {
  margin: 70px 0px;
}
.contact-us-section-heading {
  color: var(--secondary);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  text-transform: uppercase;
}
.contact-us-section-heading-2 {
  color: var(--secondary);
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 50px;
}
.contact-us-section-heading-2 > span {
  color: var(--primary);
}
.contact-input {
  border: none;
  overflow: hidden;
  background: none;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  height: 58px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px,
    rgb(0 0 0 / 6%) 0px 0px 0px 1px;
  margin-bottom: 15px;
}
.contact-textarea {
  border: none;
  overflow: hidden;
  background: none;
  outline: none;
  background: #ffffff;
  box-sizing: border-box;
  height: 120px;
  width: 100%;
  padding: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: none;
  box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px,
    rgb(0 0 0 / 6%) 0px 0px 0px 1px;
  /* margin-bottom: 15px; */
  resize: none;
}
.error {
  color: var(--primary);
  font-size: 16px;
  margin-bottom: 15px;
}
#modal-heading{
  color: var(--secondary);
  font-size: 20px;
  font-weight: 700;
}
#modal-message{
  color: var(--secondary);
  font-size: 16px;
  margin-top: 15px;
}
.contact-form-submit-button {
  width: 100% !important;
  height: 45px !important;
  padding: 0px !important;
  margin: 0px !important;
  min-width: 100% !important;
  background-color: var(--primary) !important;
  border-radius: 0px !important;
  color: var(--white) !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}
.contact-section-map-main {
  width: 100%;
  height: 100%;
  background-image: url("./../assets/images/map-circle.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-section-map {
  position: absolute;
  border-radius: 100px;
  -moz-border-radius: 100px;
  overflow: hidden;
  border-radius: 50%;
  width: 420px;
  height: 420px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 1430px) {
  .contact-section-map {
    width: 410px;
    height: 410px;
  }
}
@media only screen and (max-width: 1380px) {
  .contact-section-map {
    width: 400px;
    height: 400px;
  }
}
@media only screen and (max-width: 1350px) {
  .contact-section-map {
    width: 380px;
    height: 380px;
  }
}
@media only screen and (max-width: 1300px) {
  .contact-section-map {
    width: 370px;
    height: 370px;
  }
}
@media only screen and (max-width: 1250px) {
  .contact-section-map {
    width: 350px;
    height: 350px;
  }
}
@media only screen and (max-width: 1199px) {
  .contact-section-map {
    width: 430px;
    height: 430px;
  }
}
@media only screen and (max-width: 1150px) {
  .contact-section-map {
    width: 410px;
    height: 410px;
  }
}
@media only screen and (max-width: 1100px) {
  .contact-section-map {
    width: 400px;
    height: 400px;
  }
}
@media only screen and (max-width: 1070px) {
  .contact-section-map {
    width: 390px;
    height: 390px;
  }
}
@media only screen and (max-width: 1050px) {
  .contact-section-map {
    width: 370px;
    height: 370px;
  }
}
@media only screen and (max-width: 1010px) {
  .contact-section-map {
    width: 350px;
    height: 350px;
  }
}
@media only screen and (max-width: 960px) {
  .contact-section-map {
    width: 340px;
    height: 340px;
  }
}
@media only screen and (max-width: 930px) {
  .contact-section-map {
    width: 330px;
    height: 330px;
  }
}
@media only screen and (max-width: 915px) {
  .contact-section-map {
    width: 320px;
    height: 320px;
  }
}
@media only screen and (max-width: 899px) {
  .contact-us-section-container {
    margin: 20px 20px;
  }
  .contact-section-map-main {
    width: 350px;
    height: 350px;
    margin: 0 auto;
  }
  .contact-us-section-heading-2 {
    margin-bottom: 10px;
  }
  .contact-section-map {
    width: 300px;
    height: 300px;
  }
  .contact-input {
    height: 48px;
    margin-bottom: 10px;
  }
  .contact-textarea {
    height: 100px;
    /* margin-bottom: 10px; */
  }
  .error {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 730px) {
  .contact-section-map-main {
    width: 320px;
    height: 320px;
  }
  .contact-section-map {
    width: 280px;
    height: 280px;
  }
}
@media only screen and (max-width: 670px) {
  .contact-section-map-main {
    width: 300px;
    height: 300px;
  }
  .contact-section-map {
    width: 260px;
    height: 260px;
  }
}
@media only screen and (max-width: 620px) {
  .contact-section-map-main {
    width: 290px;
    height: 290px;
  }
  .contact-section-map {
    width: 250px;
    height: 250px;
  }
}
@media only screen and (max-width: 599px) {
  .contact-section-map-main {
    width: 320px;
    height: 320px;
  }
  .contact-section-map {
    width: 280px;
    height: 280px;
  }
  .contact-us-section-heading {
    font-size: 20px;
  }
  .contact-us-section-heading-2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .contact-section-map-main {
    width: 300px;
    height: 300px;
  }
  .contact-section-map {
    width: 260px;
    height: 260px;
  }
}
@media only screen and (max-width: 380px) {
  .contact-section-map-main {
    width: 270px;
    height: 270px;
  }
  .contact-section-map {
    width: 230px;
    height: 230px;
  }
  .contact-us-section-heading {
    font-size: 16px;
  }
  .contact-us-section-heading-2 {
    font-size: 16px;
    margin-bottom: 2px;
  }
}
@media only screen and (max-width: 300px) {
  .contact-section-map-main {
    width: 230px;
    height: 230px;
  }
  .contact-section-map {
    width: 200px;
    height: 200px;
  }
}
