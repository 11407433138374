.not-found-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-found-1-heading {
  color: var(--primary);
  font-weight: 700;
  font-style: normal;
  font-size: 50px;
}
.not-found-1-sub-heading {
  font-weight: 700;
  font-style: normal;
  color: var(--primary);
  font-size: 20px;
}
.not-found-error-code {
  color: var(--primary);
  font-weight: 700;
  font-style: normal;
  text-align: left;
  font-size: 150px;
  margin-top: 15vh;
}
.not-found-error-code-message {
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: var(--primary);
  font-size: 60px;
  margin-top: -8vh;
  margin-left: 57px;
}
.not-found-image {
  width: 100%;
}
.not-found-button {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  padding: 0px !important;
  border-radius: 100px !important;
  width: 50% !important;
  height: 50px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 16px !important;
  margin-top: 5vh !important;
  min-width: 250px !important;
}
.not-found-button:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
}
.not-found-image-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.not-found-button-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 1350px) {
  .not-found-1-heading {
    font-size: 40px;
  }
  .not-found-1-sub-heading {
    font-size: 16px;
  }
  .not-found-error-code {
    font-size: 120px;
  }
  .not-found-error-code-message {
    font-size: 50px;
    margin-top: -6vh;
    margin-left: 45px;
  }
}
@media only screen and (max-width: 1100px) {
  .not-found-1-heading {
    font-size: 30px;
  }
  .not-found-1-sub-heading {
    font-size: 12px;
  }
  .not-found-error-code {
    font-size: 100px;
  }
  .not-found-error-code-message {
    font-size: 40px;
    margin-top: -5vh;
    margin-left: 40px;
  }
}
@media only screen and (max-width: 899px) {
  .not-found-1-heading {
    text-align: center;
    font-size: 50px;
  }
  .not-found-1-sub-heading {
    text-align: center;
    font-size: 20px;
  }
  .not-found-error-code {
    text-align: center;
    font-size: 150px;
    margin-top: 5vh;
  }
  .not-found-error-code-message {
    text-align: center;
    font-size: 60px;
    margin-top: -8vh;
    margin-left: 120px;
  }
  .not-found-image-main {
    margin-top: 5vh;
  }
  .not-found-image {
    width: auto;
    height: 250px;
  }
}
@media only screen and (max-width: 500px) {
  .not-found-1-heading {
    font-size: 40px;
  }
  .not-found-1-sub-heading {
    font-size: 16px;
  }
  .not-found-error-code {
    font-size: 120px;
    margin-top: 5vh;
  }
  .not-found-error-code-message {
    font-size: 50px;
    margin-top: -6vh;
    margin-left: 110px;
  }
  .not-found-image-main {
    margin-top: 10vh;
  }
}
@media only screen and (max-width: 400px) {
  .not-found-1-heading {
    font-size: 30px;
    margin: 0px 10px;
  }
  .not-found-1-sub-heading {
    font-size: 12px;
    margin: 0px 10px;
  }
  .not-found-error-code {
    font-size: 80px;
    margin-top: 5vh;
    margin: 0px 10px;
  }
  .not-found-error-code-message {
    font-size: 30px;
    margin-top: -4vh;
    margin-left: 60px;
  }
  .not-found-image-main {
    margin-top: 10vh;
  }
}
