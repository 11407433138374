.drawer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.drawer-logo {
  height: 40px;
}
.drawer-container {
  background-color: var(--white);
  height: 100vh;
}
.drawer-close-button {
  padding: 0px !important;
  width: 35px !important;
  min-width: 35px !important;
  height: 35px !important;
  min-height: 35px !important;
  border-radius: 100px !important;
  color: var(--white) !important;
  font-size: 25px !important;
  color: var(--secondary) !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}
.drawer-item {
  padding: 5px !important;
  margin: 0px !important;
  justify-content: flex-start !important;
  width: 94% !important;
  margin-left: 3% !important;
  margin-top: 10px !important;
  color: var(--secondary) !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  align-items: center !important;
}
.drawer-active-item {
  padding: 5px !important;
  margin: 0px !important;
  justify-content: flex-start !important;
  width: 94% !important;
  margin-left: 3% !important;
  margin-top: 10px !important;
  color: var(--primary) !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  align-items: center !important;
  background-color: var(--lightGray) !important;
}
.drawer-item-icon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.drawer-services-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}
.drawer-services-down-arrow {
  width: 20px;
  height: 20px;
}
.drawer-services-list-container {
  border: 1px solid var(--secondary);
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 10px;
}
.drawer-services-list-active-container {
  border: 1px solid var(--primary);
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
  margin-top: 10px;
}
.drawer-dropdown-item {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: var(--secondary) !important;
  text-transform: capitalize !important;
  padding: 5px 10px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  opacity: 1px !important;
}
.drawer-dropdown-active-item {
  font-weight: 500 !important;
  font-size: 13px !important;
  color: var(--primary) !important;
  text-transform: capitalize !important;
  padding: 5px 10px !important;
  width: 100% !important;
  justify-content: flex-start !important;
  opacity: 1px !important;
}
.drawer-get-quote-button {
  font-weight: 700 !important;
  font-size: 15px !important;
  color: var(--white) !important;
  text-transform: capitalize !important;
  padding: 0px !important;
  background-color: var(--primary) !important;
  border-radius: 0px !important;
  height: 40px !important;
  width: 94% !important;
  margin-left: 3% !important;
  margin-top: 15px !important;
}
