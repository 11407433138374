/* section 1 */
.home-section-1 {
  background-image: url("./../assets/images/home-section-1-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
}
.home-section-1-c-1-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.home-section-1-heading {
  font-size: 19px;
  font-weight: 700;
  color: var(--secondary);
  text-transform: uppercase;
}
.home-section-1-line {
  height: 1px;
  width: 150px;
  background-color: var(--secondary);
  margin-left: 20px;
}
.home-section-1-heading-2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--secondary);
  line-height: 45px;
}
.home-section-1-country-name {
  color: var(--primary);
  text-transform: uppercase;
}
.home-section-1-button {
  font-size: 16px !important;
  color: var(--secondary) !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  border: 2px solid var(--secondary) !important;
  border-radius: 0px !important;
  height: 44px !important;
  padding: 0px 30px !important;
  margin-top: 50px !important;
}
.home-section-1-button:hover {
  color: var(--white) !important;
  border: 2px solid var(--primary) !important;
  background-color: var(--primary) !important;
}
.home-section-1-c-2-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.home-section-1-user-image {
  height: 400px;
  width: auto;
}
.home-section-1-image {
  height: 440px;
  width: auto;
  position: absolute;
}

/* section 2 */
.home-section-2 {
  padding: 100px 0px;
}
.home-section-2-c-2-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.home-section-2-heading {
  color: var(--primary);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: left;
}
.home-section-2-description {
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 35px;
  text-align: justify;
  font-size: 17px;
}
.home-section-2-button-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.home-section-2-button {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
  height: 45px !important;
  padding: 0px 50px !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  border-radius: 0px !important;
}
.home-section-2-button:hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}
.home-section-3-image {
max-width: 100%;
max-height: 100%;
}
.home-section-2-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
}
/* section 3 */
.home-section-3 {
  background-image: url("./../assets/images/home-section-3.png");
  height: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-bottom: 130px;
}
.home-section-3-inner {
  background-color: var(--primaryLight);
  margin-top: 100px;
  padding: 70px 0px;
}
.home-section-3-title {
  color: var(--white);
  text-align: center;
  font-size: 18px;
}
.home-section-3-counter {
  color: var(--white);
  text-align: center;
  font-weight: 700;
  font-size: 25px;
}
.home-section-3-data {
  padding: 10px 0px;
  margin-bottom: 10px;
}
.home-section-3-data-2 {
  padding: 10px 0px;
  border-left: 1px solid var(--white);
  border-right: 1px solid var(--white);
  margin-bottom: 10px;
}
/*  section 4 */
.home-section-4 {
  margin-bottom: 50px;
  margin-top: 10px;
  background-color: var(--sectionBg);
  padding: 40px 0px;
}
.home-section-4-heading {
  text-align: center;
  color: var(--secondary);
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 50px;
}
.home-section-4-heading > span {
  color: var(--primary);
}
.home-section-4-icon-main {
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  background-color: var(--primary);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-section-4-icon {
  height: 40px;
}
.home-section-4-heading-2 {
  color: var(--black);
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-top: 15px;
}
.home-section-4-description {
  color: var(--secondary);
  font-size: 13px;
  text-transform: none;
  margin-top: 15px;
}
.home-section-4-card {
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px !important;
  text-transform: none !important;
  margin-top: 10px !important;
  border-radius: 0px !important;
  justify-content: flex-start !important;
  background-color: transparent !important;
}
/* section 5 */
.home-section-5 {
  background-image: url("./../assets/images/home-section-3.png");
  height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  margin-bottom: 150px;
}
.home-section-5-inner {
  background-color: var(--primaryLight);
  margin-top: 100px;
  padding: 50px 0px 50px 30px;
}
.home-section-5-heading {
  color: var(--white);
  font-weight: 700;
  font-size: 22px;
  text-transform: capitalize;
}
.home-section-5-description {
  color: var(--white);
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
  text-align: justify;
}
/*  section 6 */
.home-section-6 {
  margin-bottom: 50px;
}
.active {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: var(--primary);
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.inactive {
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: var(--black);
  margin-left: 3px;
  margin-right: 3px;
  cursor: pointer;
}
.mobile-phone-mockup-container {
  position: relative;
  height: 700px;
  width: 350px;
  margin: 0 auto;
  min-height: 700px;
  max-height: 700px;
}
.mobile-phone-mockup-image {
  min-height: 700px;
  max-height: 700px;
  width: 350px;
}
.mobile-phone-mockup-slider-container {
  position: absolute;
  top: 0px;
  width: 300px;
  margin-top: 68px;
  margin-left: 20px;
}
.mobile-phone-mockup-slider {
  height: 565px;
  width: 100%;
  margin-bottom: 30px;
}
.mobile-phone-mockup-slider-image {
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1650px) {
  /* section 4 */
  .home-section-4-heading-2 {
    font-size: 16px;
    margin-top: 10px;
  }
  .home-section-4-description {
    font-size: 12px;
  }
}
@media only screen and (max-width: 1450px) {
  /* section 1 */
  .home-section-1-heading-2 {
    font-size: 35px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 1300px) {
  /* section 1 */
  .home-section-1-heading-2 {
    font-size: 30px;
    line-height: 35px;
  }
  .home-section-1-heading {
    font-size: 16px;
  }
  .home-section-1-user-image {
    height: 350px;
  }
  .home-section-1-image {
    height: 390px;
  }
  .home-section-1-line {
    width: 100px;
  }
}
@media only screen and (max-width: 1299px) {
  /* section 4 */
  .home-section-4-icon-main {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .home-section-4-icon {
    height: 30px;
  }
  .home-section-4-heading-2 {
    font-size: 12px;
    margin-top: 10px;
  }
  .home-section-4-description {
    font-size: 12px;
    margin-top: 10px;
  }
  .mobile-phone-mockup-container {
    height: 600px;
    width: 300px;
    min-height: 600px;
    max-height: 600px;
    margin-left: 0px;
  }
  .mobile-phone-mockup-image {
    min-height: 600px;
    max-height: 600px;
    width: 300px;
  }
  .mobile-phone-mockup-slider-container {
    width: 255px;
    margin-top: 58px;
    margin-left: 18px;
  }
  .mobile-phone-mockup-slider {
    height: 485px;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 899px) {
  /* section 1 */
  .home-section-1-heading-2 {
    font-size: 25px;
    line-height: 30px;
  }
  .home-section-1-heading {
    font-size: 14px;
  }
  .home-section-1-user-image {
    height: 300px;
  }
  .home-section-1-image {
    height: 330px;
  }
  .home-section-1-line {
    width: 70px;
  }

  .home-section-2-description {
    line-height: 25px;
    font-size: 14px;
  }
  /* section 4 */
  .home-section-4-icon-main {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .home-section-4-icon {
    height: 40px;
  }
  .home-section-4-heading-2 {
    font-size: 18px;
    margin-top: 10px;
  }
  .home-section-4-description {
    font-size: 13px;
    margin-top: 10px;
  }
  /* section 5 */
  .home-section-5-heading {
    font-size: 18px;
  }
  .home-section-5-description {
    font-size: 16px;
    line-height: 27px;
  }
  .mobile-phone-mockup-container {
    margin: 0 auto;
  }
}
@media only screen and (max-width: 837px) {
  /* section 4 */
  .home-section-4-icon-main {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .home-section-4-icon {
    height: 30px;
  }
  .home-section-4-heading-2 {
    font-size: 15px;
  }
  .home-section-4-description {
    font-size: 12px;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 750px) {
  /* section 1 */
  .home-section-1-heading-2 {
    font-size: 20px;
    line-height: 25px;
  }
  .home-section-1-heading {
    font-size: 12px;
  }
  .home-section-1-user-image {
    height: 200px;
  }
  .home-section-1-image {
    height: 210px;
  }
  .home-section-1-line {
    width: 70px;
  }
  .home-section-1-button {
    font-size: 13px !important;
    height: 35px !important;
    padding: 0px 20px !important;
    margin-top: 30px !important;
  }
  /* section 3 */
  .home-section-3-title {
    font-size: 14px;
  }
  .home-section-3-counter {
    font-size: 20px;
  }
}
@media only screen and (max-width: 599px) {
  /* section 1 */
  .home-section-1 {
    padding: 100px 20px;
  }
  .home-section-1-heading {
    font-size: 19px;
  }
  .home-section-1-line {
    width: 150px;
  }
  .home-section-1-heading-2 {
    font-size: 35px;
    line-height: 40px;
  }
  .home-section-1-button {
    font-size: 16px !important;
    height: 44px !important;
    padding: 0px 30px !important;
    margin-top: 50px !important;
  }
  .home-section-1-c-2-container {
    margin-top: 40px;
  }
  .home-section-1-user-image {
    height: 350px;
  }
  .home-section-1-image {
    height: 390px;
  }
  .home-section-1-c-2-container {
    justify-content: center;
  }
  /* section 2 */
  .home-section-2 {
    padding: 50px 20px;
  }
  .home-section-2-c-2-container {
    margin-top: 20px;
  }
  .home-section-2-description {
    line-height: 35px;
    font-size: 17px;
  }

  /* section 3 */
  .home-section-3 {
    height: auto;
    margin-bottom: 50px;
  }
  .home-section-3-inner {
    margin-top: 0px;
    padding: 70px 0px;
  }
  .home-section-3-data-2 {
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .home-section-3-title {
    font-size: 18px;
  }
  .home-section-3-counter {
    font-size: 25px;
  }
  /* section 4 */
  .home-section-4-card {
    height: auto !important;
  }
  .home-section-4 {
    padding: 40px 20px;
  }
  .home-section-4-heading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .home-section-4-icon-main {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
  .home-section-4-icon {
    height: 40px;
  }
  .home-section-4-heading-2 {
    font-size: 20px;
    margin-top: 15px;
  }
  .home-section-4-description {
    font-size: 13px;
    margin-top: 15px;
  }
  /* section 5 */
  .home-section-5 {
    background-image: url("./../assets/images/home-section-5.jpg");
    height: auto;
    margin-bottom: 50px;
  }
  .home-section-5-inner {
    background-color: var(--primaryLight);
    margin-top: 0px;
    padding: 50px 20px 50px 20px;
  }
}
@media only screen and (max-width: 500px) {
  /* section 1 */
  .home-section-1-heading-2 {
    font-size: 25px;
    line-height: 30px;
  }
  .home-section-1-heading {
    font-size: 14px;
  }
  .home-section-1-user-image {
    height: 300px;
  }
  .home-section-1-image {
    height: 330px;
  }
  .home-section-1-line {
    width: 70px;
  }
  .home-section-1 {
    padding: 70px 20px;
  }
  /* section 4 */
  .home-section-4-heading {
    font-size: 16px;
  }
  /*  section 5 */
  .home-section-5-heading {
    font-size: 16px;
  }
  .home-section-5-description {
    font-size: 14px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 400px) {
  /* section 1 */
  .home-section-1-user-image {
    height: 250px;
  }
  .home-section-1-image {
    height: 280px;
  }
  /* section 4 */
  .home-section-4-icon-main {
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
  .home-section-4-icon {
    height: 35px;
  }
  .home-section-4-heading-2 {
    font-size: 18px;
    margin-top: 15px;
  }
  /*  section 5 */
  .home-section-5-heading {
    font-size: 14px;
  }
  .home-section-5-description {
    font-size: 12px;
    line-height: 21px;
  }
}
@media only screen and (max-width: 360px) {
  /* section 1 */
  .home-section-1-user-image {
    height: 150px;
  }
  .home-section-1-image {
    height: 170px;
  }
  .home-section-1-button {
    font-size: 12px !important;
    height: 30px !important;
    padding: 0px 20px !important;
    margin-top: 30px !important;
  }
  .home-section-1-heading-2 {
    font-size: 16px;
    line-height: 21px;
  }
  .home-section-1-heading {
    font-size: 10px;
  }
  .home-section-1-line {
    width: 70px;
    margin-left: 10px;
  }
  /* section 2 */
  .home-section-2-description {
    line-height: 24px;
    font-size: 14px;
  }

  .home-section-2-button {
    height: 35px !important;
    padding: 0px 30px !important;
    font-size: 11px !important;
  }
  /* section 4 */
  .home-section-4-heading {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .home-section-4-icon-main {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
  }
  .home-section-4-icon {
    height: 30px;
  }
  .home-section-4-heading-2 {
    font-size: 12px;
    margin-top: 10px;
  }
  .home-section-4-description {
    font-size: 11px;
    margin-top: 10px;
  }
}
